// Variables
@import "_variables";
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{
    font-family: 'Open Sans', sans-serif!important;
}
.title-image-back{
  .tag{
    max-width: 100%;
    left: auto;
    h1{
      font-size: 3rem;
    }
  }
}
.navbar-brand{
  height: auto;
  img{
    max-height: 80px;
  }
}
nav.navbar.bootsnav {
  border: none;
  ul.nav {
    li {
      &.active{
        a{
          background-color:$primary_color!important;
          color: #FFFFFF;
        }
      }
       a{
         font-size: 1.2rem;
         font-family: 'Raleway', sans-serif;
         color: #000000;
         font-weight: 800;
         padding: 20px 15px;
         text-transform: uppercase;
         @media(min-width: 992px){
           height: 110px;
           line-height: 90px;
         }
       }
     }
   }
 }
.btn{
  border-radius: 3px;
}
h1, h2, h3, h4, h5{
  font-weight:900;
  font-family: 'Raleway', sans-serif;
}
p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}

.full-screen-billboard{
  h2{
    font-size: 4.5rem;
    font-weight: 800;
  }
  h3{
    font-size: 3rem;
    font-weight: 800;
  }
}
.title-image-back{
  &.pattern{
    background: #eee url('/wp-content/themes/polarplunge-theme/img/overlay-pattern-1.png') repeat!important;
  }
  height: 125px;
  .tag{
    @media(max-width: 767px){
      bottom: 5%;
    }
  }
}
.base-footer{
  display: none;
}

/* FORMS */
.select2-container{
  max-width: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    font-size: .8em;
    line-height: 2em;
}

.select2-results__option {
    padding: 10px;
    font-size: .75em;
    line-height: 1.5em;
}

.count_down{
  .background{
    &.time{
      width:100%;
      text-align: center;
      float: left;
      .counter, .title{
        width: 100%;
        float: left;
        font-family: $header_font;
        font-weight: 600;
      }
      .counter{
        font-size: 3.2rem;
      }
      .title{
        font-size: 2rem;
        @media(max-width: 767px){
          font-size: 1.2rem;
        }
      }
    }
  }
}

.sponsor{
  padding: 10px 25px;
  img{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sponsor-block{
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 100%;
  @media(min-width: 768px){
  display: table;
  position: relative;
  min-height: 220px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  h4{
    @media(min-width: 768px){
    display: table-cell;
    vertical-align: middle;
    }
  }
  img{
    @media(min-width: 768px){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media(max-width: 767px){
      margin: auto;
    }
  }

}
